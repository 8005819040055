import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Header from "./Header/Header";
import "./Company.css";
import GIFSection from "./ContentSections/GIFSection/GIFSection";
import { Container, Row, Col } from "react-bootstrap";
import ContentSection from "./ContentSections/ContentSection/ContentSection";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { MdTextsms, MdEmail } from "react-icons/md";
import Typography from "./general/Typography/Typography";
import parsePhoneNumber from "libphonenumber-js";
import VoicemailPlayer from "./ContentSections/ContentSection/VoicemailPlayer";
import ContactForm from "./ContactForm";
import SocialProof from "./SocialProof";
import SigmaImage from "./SigmaImage";
import CircleGraphs from "./ContentSections/CircleGraphs/CircleGraphs";
import Loader from "react-loader-spinner";
import ReactGA from "react-ga";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { getDateString } from "../util";

const Company = () => {
  const { website } = useParams();
  const [isLoadingCompany, setIsLoadingCompany] = useState(true);
  const [error, setError] = useState(null);
  const [company, setCompany] = useState(null);
  const [formData, setFormData] = useState(null);
  const [audit, setAudit] = useState(null);
  const loadFormSubmissions = (company_id) => {
    fetch(
      `${process.env.REACT_APP_API_URL}/form_submissions?company_id=${company_id}`
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.length === 0) {
          setError(true);
          setIsLoadingCompany(false);
        } else {
          const forms = res.filter((f) => {
            return f?.form_status?.id !== 7;
          });
          setFormData(forms);
          setAudit(res[res.length - 1].audit);
          setIsLoadingCompany(false);
        }
      })
      .catch((error) => {});
  };
  const loadCompany = (website) => {
    fetch(`${process.env.REACT_APP_API_URL}/companies?website=${website}`)
      .then((response) => response.json())
      .then((res) => {
        if (res.length === 0) setError(true);
        else setCompany(res[0]);
      })
      .catch((error) => {
        setError(true);
      });
  };

  useEffect(() => {
    ReactGA.initialize("UA-178242247-1");
  }, []);

  useEffect(() => {
    loadCompany(website);
  }, [website]);

  useEffect(() => {
    if (company) loadFormSubmissions(company.id);
  }, [company]);

  const getResponseType = (type) => {
    if (type === "voice") return "Call";
    if (type === "text") return "Text Message";
    return "Email";
  };

  const renderResponseInfo = (type, responseInfo, autoPlay) => {
    if (type === "voice") {
      if (!responseInfo)
        return <Typography size="medium">No voicemail</Typography>;
      return <VoicemailPlayer autoPlay={autoPlay} recording={responseInfo} />;
    }
    if (type === "email")
      return (
        <Typography size="medium">
          {responseInfo.replace(/\n\s*\n/g, "\n")}
        </Typography>
      );
    return <Typography size="medium">{responseInfo}</Typography>;
  };

  const renderIcon = (type, color) => {
    if (type === "voice")
      return (
        <FaPhoneSquareAlt
          size={"2.5em"}
          className={`response-icon-${color} mr-3`}
        />
      );
    if (type === "email")
      return (
        <MdEmail size={"2.5em"} className={`response-icon-${color} mr-3`} />
      );
    return (
      <MdTextsms size={"2.5em"} className={`response-icon-${color} mr-3`} />
    );
  };

  const getResponseFrom = (type, from) => {
    if (type === "voice" || type === "text")
      return parsePhoneNumber(from).formatNational();
    if (type === "email") return from;
  };

  const ResponseItem = ({ type, from, date, responseInfo, autoPlay }) => {
    return (
      <Col xs={12} className="mb-3 p-0 p-md-3 d-flex justify-content-center">
        <Row className="response-item p-0 p-md-3">
          <Col
            xs={12}
            xl={6}
            className="d-flex align-items-center px-0 from-txt"
          >
            {renderIcon(type, "green")}
            <Typography size="large">{`${getResponseType(
              type
            )} From ${getResponseFrom(type, from)}`}</Typography>
          </Col>
          <Col
            xs={12}
            xl={6}
            className="p-0 d-flex align-items-center justify-content-xl-end justify-content-start"
          >
            <Typography size="medium" txtStyle="italics">{`${date}`}</Typography>
          </Col>
          <hr className="black-line-break" />
          <div className="float-left w-100">
            {renderResponseInfo(type, responseInfo, autoPlay)}
          </div>
        </Row>
      </Col>
    );
  };

  const renderNoResponse = (type) => {
    return (
      <Row className="w-100 d-flex justify-content-center m-0 p-0">
        <Row className="align-items-center no-response-item p-0 p-md-3 m-0 flex-nowrap">
          {renderIcon(type, "red")}
          <Typography
            color="red"
            size="large"
          >{`No ${type} was attempted`}</Typography>
        </Row>
      </Row>
    );
  };

  if (error)
    return (
      <Container fluid>
        <Row className="w-100 justify-content-center mt-5 ml-0 mr-0">
          <Row className="w-sx-50 w-md-100 company-error justify-content-center p-3">
            <Typography size="large" txtStyle="bold" center>
              <Typography size="xxlarge" stype="bold" center>
                Something went wrong!
              </Typography>{" "}
              Please try reloading the page.
            </Typography>
          </Row>
        </Row>
      </Container>
    );

  return (
    <Container
      fluid
      className="d-flex flex-column align-items-center company-container p-0"
    >
      {isLoadingCompany ? (
        <div className="row">
          <div className="col-sm-12 text-center p-5">
            <Typography size="xxlarge" color="green">
              Loading...
            </Typography>
            <Loader type="ThreeDots" color="#34d1b6" height={160} width={160} />
          </div>
        </div>
      ) : (
        <>
          <Header />
          <GIFSection audit={audit} formData={formData} />
          <ContentSection audit={audit} label="Phone" type="voice">
            {formData && formData[formData.length - 1]?.voice_messages?.length > 0
              ? formData[formData.length - 1].voice_messages?.map((voice, index) => (
                  <ResponseItem
                    key={voice.id}
                    type="voice"
                    from={voice.from}
                    date={getDateString(voice.created_at)}
                    responseInfo={voice.recording}
                    autoPlay={index === 0}
                  />
                ))
              : renderNoResponse("phone call")}
          </ContentSection>
          <ContentSection audit={audit} label="Email" type="email">
            {formData && formData[formData.length - 1]?.email_messages?.length > 0
              ? formData[formData.length - 1].email_messages?.map((email) => (
                  <ResponseItem
                    key={email.id}
                    type="email"
                    from={email.from}
                    date={getDateString(email.created_at)}
                    responseInfo={email.body}
                  />
                ))
              : renderNoResponse("email")}
          </ContentSection>
          <ContentSection audit={audit} label={"Text Message"} type="text">
            {formData && formData[formData.length - 1]?.text_messages?.length > 0
              ? formData[formData.length - 1].text_messages?.map((text) => (
                  <ResponseItem
                    key={text.id}
                    type="text"
                    from={text.from}
                    date={getDateString(text.created_at)}
                    responseInfo={text.body}
                  />
                ))
              : renderNoResponse("text message")}
          </ContentSection>
          <CircleGraphs audit={audit} />
          <SigmaImage />
          <SocialProof />
          <ContactForm />
        </>
      )}
    </Container>
  );
};

export default Company;
